export const list = "{ paymentParameters { PaymentParametersType, PaymentParametersName, expiresIn, deniedBeforeDate, deniedAfterDate, maximumAmount, minimumAmount, maximumOccurrence, recurrencePeriod, validationLinkURL, enabled } }"

export const create = (parameters) => {
  return `mutation { createPaymentParameters(paymentParametersData: ${parameters}) {
              paymentParameters {
                  PaymentParametersType, PaymentParametersName, expiresIn, deniedBeforeDate, deniedAfterDate, maximumAmount, minimumAmount, maximumOccurrence, recurrencePeriod, validationLinkURL, enabled
                } 
              }
            }`
}

export const update = (parameters) => {
  return `mutation { updatePaymentParameters(paymentParametersData: ${parameters}) { 
              paymentParameters { 
                PaymentParametersType, PaymentParametersName, expiresIn, deniedBeforeDate, deniedAfterDate, maximumAmount, minimumAmount, maximumOccurrence, recurrencePeriod, validationLinkURL, enabled 
              } 
            } 
          }`
}