import * as merchants from './merchants'
import * as users from './users'
import * as transactions from './transactions'
import * as paymentParameters from './paymentParameters'

import { createClient } from 'graphql-ws';
import { API } from 'aws-amplify'

const handleResponse = (response, onError = false) => {
    if (response.status !== 200) {
        return response
            .json()
            .then(error => {
                return Promise.reject(onError ? onError : error)
            })
            .catch(err => {
                if (response.status === 401 || response.status === 403) {
                    window.location = '/unauthorized'
                    return Promise.reject(
                        `unauthorized access attempt:${response.status}`
                    )
                }
                else {
                    return Promise.reject(onError ? onError : err)
                }
            })
    }
    else {
        try {
            return response.json()
        }
        catch (error) {
            return Promise.reject(error)
        }
    }
}

const formatToken = (token) => {
    const strigifiedToken = JSON.stringify(token)
    const encodedToken = btoa(strigifiedToken)
    const uriEncodedToken = encodeURI(encodedToken)
    return uriEncodedToken
}

const createSocket = (accessToken, connect) => {
    let token = {
        jwtAccessToken: accessToken,
        userPoolId: process.env.REACT_APP_USER_POOL
    }
    const formattedToken = formatToken(token)
    return createClient({
        url: `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_PARTNER}/?security_tokens=${formattedToken}`,
        connectionAckWaitTimeout: Infinity,
        lazy: false,
        onNonLazyError: (errorOrCloseEvent) => {
            console.log(errorOrCloseEvent)
        },
        on: {
            connected: (socket) => {
                connect(socket)
            },
            pong: (payload) => {
                console.log('pong',payload)
            },
            error: (err) => {
                console.error(err);
            },
        }
      });
  }

const socketQuery = async(websocket, query) => {
    return await new Promise((resolve, reject) => {
        let result;
        websocket.subscribe(
          {
            query: query,
          },
          {
            next: (data) => result = data,
            error: reject,
            complete: () => resolve(result),
          },
        )
      })     
}

const appSyncQuery = (query, variables = {}) => {
    return API.graphql({query, variables})
}


export { merchants, users, handleResponse, transactions, createSocket, socketQuery, paymentParameters, appSyncQuery }
