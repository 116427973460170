import React, {createContext, lazy, Suspense, useCallback, useEffect, useState} from 'react'

import {BooksHooks, ModalSpinner, NotFound} from '@paytheory/pay-theory-ui'

import {generateMenu} from './views/util'

import './App.css'

import {Navigate, Route, Routes} from 'react-router-dom'

import {withAuthenticator} from "@aws-amplify/ui-react";

import * as Login from './components/LoginComponents'

import * as network from './network'

import * as ROUTES from './constants/routes'

const Font = lazy(() =>
    import ('@paytheory/pay-theory-ui/dist/Font'))

// const Unauthorized = lazy(() =>
//   import ('@paytheory/pay-theory-ui'))

// const NotFound = lazy(() =>
//   import ('@paytheory/pay-theory-ui'))

const ManageAdmins = lazy(() =>
    import ('./views/ManageAdmins'))

const ManageMerchants = lazy(() =>
    import ('./views/ManageMerchants'))

const MerchantDetail = lazy(() =>
    import ('./views/MerchantDetail'))

const PaymentParameters = lazy(() =>
    import ('./views/PaymentParameters'))

const PaymentParametersDetail = lazy(() =>
    import ('./views/PaymentParametersDetail'))

export const AppContext = createContext()

export const App = ({user, signOut}) => {
    const [token, setToken] = useState(false)
    const [connected, setConnected] = useState(false)
    const [websocket, setWebsocket] = useState(false)
    const typekit = process.env.REACT_APP_TYPEKIT

    useEffect(() => {
        if (user && !token) {
            let accessToken = user.signInUserSession?.accessToken.jwtToken
            setToken(accessToken)
        }
    }, [user, token])

    useEffect(() => {
        if (token && connected === false) {
            let socket = network.createSocket(token, setConnected)
            setWebsocket(socket)
        }
    }, [token, connected])

    const customSignOut = useCallback(() => {
        setToken(false)
        setConnected(false)
        if (websocket) websocket.dispose()
        setWebsocket(false)
        signOut()
    }, [websocket])

    useEffect(() => {
        const minutesTillTimeout = 5
        const timeout = minutesTillTimeout * 60000
        const actions = ['mousemove', 'scroll', 'keydown', 'click', 'mousedown']

        let t = setTimeout(signOut, timeout)

        const updateTimeout = () => {
            clearTimeout(t)
            t = setTimeout(signOut, timeout)
        }

        actions.forEach((action) => {
            document.addEventListener(action, updateTimeout, {
                capture: false,
                passive: true
            })
        })

        return () => {
            actions.forEach((action) => {
                document.removeEventListener(action, updateTimeout)
            })
            clearTimeout(t)
        }
    }, [signOut])

    return (
        <div className="spinner-wrapper">
            <div className="modal-wrapper">
                <BooksHooks.Context.Menu.Provider value={generateMenu()}>
                    <AppContext.Provider value={{websocket, connected, signOut: customSignOut}}>
                        <Suspense fallback={<ModalSpinner on/>}>
                            <Routes>
                                <Route path="/" element={<Navigate replace to={ROUTES.HOME}/>}/>
                                <Route
                                    exact
                                    path={ROUTES.MANAGE_ADMINS}
                                    element={<ManageAdmins/>}
                                />
                                <Route
                                    exact
                                    path={ROUTES.MANAGE_MERCHANTS}
                                    element={<ManageMerchants/>}
                                />
                                <Route
                                    exact
                                    path={ROUTES.MERCHANT_DETAIL}
                                    element={<MerchantDetail/>}
                                />
                                <Route
                                    exact
                                    path={ROUTES.PAYMENT_PARAMETERS}
                                    element={<PaymentParameters/>}
                                />
                                <Route
                                    exact
                                    path={ROUTES.PAYMENT_PARAMETERS_DETAILS}
                                    element={<PaymentParametersDetail/>}
                                />
                                <Route
                                    path='*'
                                    element={<NotFound/>}
                                />
                            </Routes>
                            <Font typekit={typekit}/>
                        </Suspense>
                        <ModalSpinner/>
                    </AppContext.Provider>
                </BooksHooks.Context.Menu.Provider>
            </div>
        </div>
    )
}

export default withAuthenticator(App, {
    includeGreetings: false,
    loginMechanisms: ['email'],
    components: {
        Header: Login.Header,
        SignIn: {
            Header: Login.SignInHeader
        },
    }
});
